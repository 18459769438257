import * as JsSearch from 'js-search';
import * as moment from 'moment';

class Resources {
    constructor() {
        this.namespaces = [
            document.querySelector('.page-resources')
        ].filter(Boolean);

        this.siteWidth = 1100;
        this.resources = [];
        this.filteredResources = [];
        this.filterAge = document.querySelector('.filter-age');
        this.filterTheme = document.querySelector('.filter-theme');
        this.filterYear = document.querySelector('.filter-year');

        this.page = 0;
        this.totalPages = 0;
        this.maxItems = 9;

        this.initPage();
    }

    initPage() {
        if (!this.namespaces.length) return;
        this.setupWindowEvents();
        this.setupListeners();
        this.parseJSON();
    }

    parseJSON() {
        const jsonContainer = document.querySelector('.resources-json');
        if (!jsonContainer) return;
        const json = this.htmlDecode(jsonContainer.innerHTML);
        if (json) {
            json.forEach(element => {
                this.resources.push(element.attributes);
            });
        }

        this.filteredResources = this.resources;

        this.setupSearch();
        this.setupDropdowns();

        const results = this.resources.sort((a, b) => new Date(b.date.replaceAll('/','-').split('-').reverse()) - new Date(a.date.replaceAll('/','-').split('-').reverse()));
        this.renderCards(this.paginateResults(results));
        
    }

    setupDropdowns() {
        if (!this.filterTheme) return;
        this.filterTheme.innerHTML = '';
        
        const publicationTypes = this.resources.map(resource => {
            if (resource.publicationType) {
                return resource.publicationType.replaceAll('_', ' ');
            } 
        });

        const removedNulls = publicationTypes.filter(function( element ) {
            return element !== undefined;
        });

        const uniquePublicationTypes = [...new Set(removedNulls)].sort();
        

        let html = '';
        uniquePublicationTypes.forEach(resource => {
            html += `<option value="${resource}">${resource}</option>`;
        });

        this.filterTheme.innerHTML = `<option value="0">Select Publication Type</option>${html}`;
    }

    setupSearch() {
        this.search = new JsSearch.Search('title');
        this.search.addIndex(['title']);
        this.search.addDocuments(this.resources);
    }

    htmlDecode(input) {
        var doc = new DOMParser().parseFromString(input, "text/html");
        return JSON.parse(doc.documentElement.textContent);
    }

    setupListeners() {
        this.searchInput = document.querySelector('.resource-search');
        if (!this.searchInput) return;
        this.searchInput.addEventListener('input', this.searchHandler.bind(this));
        this.resourceContainer = document.querySelector('.index-inner');
        if (!this.resourceContainer) this.resourceContainer.innerHTML = '';

        this.filterAge.addEventListener('change', this.filterAgeHandler.bind(this));
        if (this.filterTheme) this.filterTheme.addEventListener('change', this.filterThemeHandler.bind(this));
        this.filterYear.addEventListener('change', this.filterYearHandler.bind(this));
    }

    filterYearHandler(e) {
        const value = e.target.value;
        let results;

        if (value === 'all') {
            results = this.resources;
            this.renderCards(this.paginateResults(results));
            return;
        }

        results = this.resources.filter(resource => resource.date.includes(value));
        this.filteredResources = results;

        if (results.length === 0) return this.renderCards([]);
        this.renderCards(this.paginateResults(results));
        
    }

    filterThemeHandler(e) {
        const value = e.target.value;
        if (value === "0") return;
        let results;

        if (value === 'all') {
            results = this.resources;
            this.renderCards(results);
            return;
        }

        results = this.resources.filter(resource => {
            if (resource.publicationType) {
                return resource.publicationType.toLowerCase() === value.replaceAll(' ', '_').toLowerCase();
            } else {
               // return resource.publicationType.toLowerCase() === value.replaceAll(' ', '_').toLowerCase();
            }
        });
        this.filteredResources = results;

        if (results.length === 0) return this.renderCards([]);
        this.renderCards(this.paginateResults(results));
    }

    filterAgeHandler(e) {
        const value = e.target.value;
        let results;

        if (value === 'asc') { 
            results = this.resources.sort((a, b) => new Date(a.date.replaceAll('/','-').split('-').reverse()) - new Date(b.date.replaceAll('/','-').split('-').reverse()));
        } else if (value === 'desc') {
            results = this.resources.sort((a, b) => new Date(b.date.replaceAll('/','-').split('-').reverse()) - new Date(a.date.replaceAll('/','-').split('-').reverse()));
        }

        this.filteredResources = results;

        if (results.length === 0) return this.renderCards([]);
        this.renderCards(this.paginateResults(results));
    }

    searchHandler(e) {
        const value = e.target.value;
        if (value === '') return this.renderCards(this.resources);
        const results = this.search.search(value);
        this.filteredResources = results;
        if (results.length === 0) return this.renderCards([]);
        this.renderCards(this.paginateResults(results))
    }

    nextPage() {
        if (this.page < this.totalPages - 1) {
            this.page++;
            this.renderCards(this.paginateResults(this.filteredResources));
        }
    }

    prevPage() {
        if (this.page > 0) {
            this.page--;
            this.renderCards(this.paginateResults(this.filteredResources));
        }
    }

    createPaginationLinks(results) {
        const pagination = document.querySelector('.pagination');
        if (!pagination) return;
        pagination.innerHTML = '';

        const prev = document.createElement('button');
        prev.classList.add('btn-prev');
        prev.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="46" height="46"><path fill="#ffd256" d="M46 23A23 23 0 1 1 23 0a23 23 0 0 1 23 23Zm-26.825-9.085L9.66 21.98 8.458 23l10.735 9.1a1.438 1.438 0 0 0 1.859-2.2L14.6 24.435h21.34a1.438 1.438 0 0 0 0-2.875H14.6l6.433-5.454a1.437 1.437 0 0 0-1.859-2.192Z"/></svg>';
        prev.addEventListener('click', this.prevPage.bind(this));
        pagination.appendChild(prev);

        for (let i = 0; i < this.totalPages; i++) {
            const link = document.createElement('button');
            link.id = `page-${i}`;
            link.classList.add('page-link');
            link.innerHTML = i + 1;
            if (i === this.page) link.classList.add('active');


            link.addEventListener('click', () => {
                this.page = i;
                document.querySelectorAll('.page-link').forEach(link => link.classList.remove('active'));
                const el = document.getElementById(`page-${i}`);
                el.classList.add('active');
                this.renderCards(this.paginateResults(results, false));
            });
            pagination.appendChild(link);
        }

        const next = document.createElement('button');
        next.classList.add('btn-next');
        next.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="46" height="46"><path fill="#ffd256" d="M46 23A23 23 0 1 1 23 0a23 23 0 0 1 23 23Zm-26.825-9.085L9.66 21.98 8.458 23l10.735 9.1a1.438 1.438 0 0 0 1.859-2.2L14.6 24.435h21.34a1.438 1.438 0 0 0 0-2.875H14.6l6.433-5.454a1.437 1.437 0 0 0-1.859-2.192Z"/></svg>';
        next.addEventListener('click', this.nextPage.bind(this));
        pagination.appendChild(next);
    }

    paginateResults(results, render=true) {
        this.totalPages = Math.ceil(results.length / this.maxItems);
        const start = this.page * this.maxItems;
        const end = start + this.maxItems;
        if (render) this.createPaginationLinks(results);
        return results.slice(start, end);
    }

    renderCards(results) {
        if (!this.resourceContainer) return;
        this.resourceContainer.innerHTML = '';

        results.forEach(result => {
            const card = this.createCard(result);
            this.resourceContainer.appendChild(card.firstChild);
        });

        if (results.length === 0) {
            const noResults = `<div class="no-results" xmlns="http://www.w3.org/1999/xhtml">Sorry no resources found</div>`;
            this.resourceContainer.appendChild(new DOMParser().parseFromString(noResults, "text/xml").firstChild);
        }
    }

    createCard(result) {
        const image = result.image.data[0] ? result.image.data[0].attributes.url : result.image.data.attributes.url;
        const researchCardCSS = window.location.pathname.includes('/our-research') ? 'research-card' : '';
        const card = `
            <article class="resource-card ${researchCardCSS}" xmlns="http://www.w3.org/1999/xhtml">
                <div class="card-image" style="background-image: url('${image}')">
                    <a class="background-link" target="_blank"  href="${window.location.pathname.includes('/our-research') ? result.reportPageUrl : result.downloadUrl}"></a>
                </div>
                <div class="inner">
                    <div>
                        <h2><a target="_blank"  href="${window.location.pathname.includes('/our-research') ? result.reportPageUrl : result.downloadUrl}">${this.trimTitle(result.title)}</a></h2>
                        <label>${window.location.pathname.includes('/resources') ? '<span>' + result.organisationAuthor.replace('&', 'and') + '</span>' : ''}<span>${this.formatDate(result.date)}</span></label>
                        ${window.location.pathname.includes('/our-research') ? `<p>${result.resource_copy}</p>` : ''}
                    </div>
                    <a href="${window.location.pathname.includes('/our-research') ? result.reportPageUrl : result.downloadUrl}" target="_blank" ><button class="primary">Find out more</button></a>
                </div>
            </article>`;

        return new DOMParser().parseFromString(card, "text/xml");
    }

    trimTitle(title) {
        return title;
        // if (window.location.pathname.includes('/our-research')) return title;
        // return title.length > 100 ? title.substring(0,100) + "..." : title;
    }

    formatDate(date) {
        return moment(date.split('/').reverse().join('-')).format("MMMM YYYY");
    }

    setupWindowEvents() 
    {
        window.addEventListener('resize', this.resizeHandler.bind(this));
    }

    resizeHandler() {
        
    }

    destroy() {
       window.removeEventListener('resize', this.resizeHandler);
    }
}
export default Resources;